<template>
  <ion-page class="page scrollable bg-transparent mt-4">
    <div class="pt-3 py-2">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="title">Manage Raffles</div>

        <ion-button class="submit-btn" @click="submitRaffle">Submit A Raffle</ion-button>
      </div>

      <div class="mt-3">
        <raffle-tabs :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
        <div class="wrapper d-flex">
          <ChLoading size="sm" v-if="isLoading" class="spinner mx-auto mt-3" />

          <div v-else-if="raffles && raffles.length" class="raffles w-100 mt-3">
            <div class="d-flex raffle">
              <div class="d-flex pb-4 card-container flex-wrap w-100">
                <horizontal-raffles v-for="(raffle, index) in raffles" :key="index" :raffle="raffle" />
              </div>
            </div>

            <p
              v-if="nextPageExists"
              class="clickable-item-hov d-flex justify-content-center align-items-center text-black"
              @click="requestLoadMore"
            >
              <span v-if="!isLoading"> Load more </span>
            </p>
          </div>

          <div v-else class="no-data">No giveaways are created yet.</div>
        </div>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import RaffleTabs from './raffle-tabs.vue';
import HorizontalRaffles from '@/shared/components/HorizontalRaffles.vue';
import { Tab } from '@/shared/types/static-types';
import { manageRaffleTabs } from '@/shared/statics/tabs';
import { getManageRaffles } from '@/shared/actions/raffles';

const router = useRouter();
const currentTab = ref('ongoing');
const tabs = ref(manageRaffleTabs);
const tabIndex = ref(0);
const paging = ref({});
const page = ref(1);

const raffles: any = ref([]);
const isLoading = ref(true);

const submitRaffle = () => {
  router.push({ name: 'create-raffle' });
};

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  raffles.value = [];
  fetchRaffles();
  router.replace({ name: 'manage-raffle', query: { tab: value } });
};

const fetchRaffles = async (offset = 1) => {
  isLoading.value = true;
  const { results, ...pagingRes } = await getManageRaffles(currentTab.value, offset, 40);
  raffles.value = !offset ? results : raffles.value.concat(results);
  paging.value = pagingRes;
  page.value = offset;
  isLoading.value = false;
};
const requestLoadMore = async (ev: MouseEvent) => {
  if (!paging.value.next) {
    (ev?.target as any).complete();
  } else {
    fetchRaffles(page.value + 1);
  }
};

const nextPageExists = computed(() => {
  return !!get(paging.value, 'next');
});
onMounted(async () => {
  fetchRaffles();
});
</script>

<style lang="sass" scoped>
.raffles
  overflow-x: auto
.raffle
  overflow-x: hidden
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
.card-container
  margin: 0 auto
  justify-content: center
.page
  position: relative
  top: -20px

.submit-btn
  --border-radius: 20px
  text-transform: none
  font-weight: bold
  height: 32px

.title
  font-size: 24px
  font-weight: bold
  #scroll-container
    border: 3px solid black
    border-radius: 5px
    overflow: hidden

.spinner
  width: 25px
  height: 25px
</style>
